<mat-sidenav-container autosize>
  <mat-sidenav
    [ngStyle]="{
      width: isCollapsed ? '100px' : '250px',
      'border-radius': isCollapsed ? 'var(--Full)' : 'var(--XXL)'
    }"
    [mode]="isMobile ? 'over' : 'side'"
    [opened]="isMobile ? 'false' : 'true'"
  >
    <div
      class="img-container"
      [ngStyle]="{
        'justify-content': isCollapsed ? 'center' : 'flex-start'
      }"
    >
      <img
        [src]="
          isCollapsed
            ? '../../../assets/fundcore-logo-comprimido.png'
            : '../../../assets/fundcore-logo-azul-oscuro.png'
        "
        [ngStyle]="{
          width: isCollapsed ? '30px' : '130px'
        }"
        alt="logo-sidebar"
      />
    </div>
    <div class="flex flex-col" style="gap: var(--L); margin-bottom: 50px">
      <!-- Home -->
      <a class="homeItem" routerLink="home" routerLinkActive="active">
        <span
          style="width: 24px; height: 24px"
          class="material-symbols-outlined"
          >house</span
        >
        <span *ngIf="!isCollapsed" class="parrafo-regular">Inicio</span>
      </a>
      <!-- Prestatarios -->
      <!-- Accordion -->
      <a
        [ngClass]="{
          openAccordion: panels[5]
        }"
        class="deployItem"
        (click)="togglePanel(5)"
        *ngIf="tienePermiso('Prestatarios', 'Acceder')"
      >
        <span
          style="width: 24px; height: 24px"
          class="material-symbols-outlined"
          >person_apron</span
        >
        <span
          style="margin-right: auto"
          *ngIf="!isCollapsed"
          class="parrafo-regular"
          >Prestatarios</span
        >
        <span
          style="
            width: 24px;
            height: 24px;
            color: var(--Foundation-Azul-Principal-500);
          "
          class="material-symbols-outlined"
          >{{ panels[5] ? "keyboard_arrow_up" : "keyboard_arrow_down" }}</span
        >
      </a>
      <!-- items -->
      <a
        class="subItem"
        [ngStyle]="{ display: panels[5] ? 'flex' : 'none' }"
        routerLinkActive="active"
        routerLink="prestatarios/consulta-prestatarios"
        *ngIf="tienePermiso('Prestatarios', 'Acceder')"
      >
        <span
          matTooltip="{{ isCollapsed ? 'Consulta de prestatarios' : '' }}"
          matTooltipPosition="after"
          style="width: 24px; height: 24px"
          class="material-symbols-outlined"
          >{{ isCollapsed ? "Apps" : "" }}</span
        >
        <span *ngIf="!isCollapsed" class="parrafo-regular"
          >Consulta de prestatarios</span
        >
      </a>
      <a
        class="subItem"
        [ngStyle]="{ display: panels[5] ? 'flex' : 'none' }"
        routerLinkActive="active"
        routerLink="prestatarios/estado-cuenta"
        *ngIf="tienePermiso('Prestatarios', 'Acceder')"
      >
        <span
          matTooltip="{{ isCollapsed ? 'Estado de cuenta' : '' }}"
          matTooltipPosition="after"
          style="width: 24px; height: 24px"
          class="material-symbols-outlined"
          >{{ isCollapsed ? "Apps" : "" }}</span
        >

        <span *ngIf="!isCollapsed" class="parrafo-regular"
          >Estado de cuenta</span
        >
      </a>
      <!-- <a
        class="subItem"
        [ngStyle]="{ display: panels[5] ? 'flex' : 'none' }"
        routerLinkActive="active"
        routerLink="prestatarios/crear-prestatario"
        *ngIf="tienePermiso('Prestatarios', 'Acceder')"
      >
        <span
          matTooltip="{{ isCollapsed ? 'Crear prestatario *' : '' }}"
          matTooltipPosition="after"
          style="width: 24px; height: 24px"
          class="material-symbols-outlined"
          >{{ isCollapsed ? "Apps" : "" }}</span
        >

        <span *ngIf="!isCollapsed" class="parrafo-regular"
          >Crear prestatario *</span
        >
      </a> -->
      <!-- Creditos -->
      <!-- Accordion -->
      <a
        class="deployItem"
        [ngClass]="{
          openAccordion: panels[0]
        }"
        (click)="togglePanel(0)"
        *ngIf="tienePermiso('Creditos', 'Acceder')"
      >
        <span
          style="width: 24px; height: 24px"
          class="material-symbols-outlined"
          >shopping_bag</span
        >
        <span
          style="margin-right: auto"
          *ngIf="!isCollapsed"
          class="parrafo-regular"
          >Créditos</span
        >
        <span
          style="
            width: 24px;
            height: 24px;
            color: var(--Foundation-Azul-Principal-500);
          "
          class="material-symbols-outlined"
          >{{ panels[0] ? "keyboard_arrow_up" : "keyboard_arrow_down" }}</span
        >
      </a>
      <!-- item -->
      <a
        class="subItem"
        routerLink="creditos/consulta-credito"
        [ngStyle]="{ display: panels[0] ? 'flex' : 'none' }"
        routerLinkActive="active"
        *ngIf="tienePermiso('Creditos', 'Acceder')"
      >
        <span
          matTooltip="{{ isCollapsed ? 'Consulta de créditos' : '' }}"
          matTooltipPosition="after"
          style="width: 24px; height: 24px"
          class="material-symbols-outlined"
          >{{ isCollapsed ? "Apps" : "" }}</span
        >
        <span *ngIf="!isCollapsed" class="parrafo-regular"
          >Consulta de créditos</span
        >
      </a>
      <a
        class="subItem"
        routerLink="creditos/crear-credito"
        [ngStyle]="{ display: panels[0] ? 'flex' : 'none' }"
        routerLinkActive="active"
        *ngIf="this.user.id_rol == '1'"
      >
        <span
          matTooltip="{{ isCollapsed ? 'Crear crédito *' : '' }}"
          matTooltipPosition="after"
          style="width: 24px; height: 24px"
          class="material-symbols-outlined"
          >{{ isCollapsed ? "Apps" : "" }}</span
        >
        <span *ngIf="!isCollapsed" class="parrafo-regular"
          >Crear crédito *</span
        >
      </a>

      <!-- Recaudo -->
      <!-- Accordion -->
      <a
        class="deployItem"
        [ngClass]="{
          openAccordion: panels[1]
        }"
        (click)="togglePanel(1)"
        *ngIf="
          tienePermiso('Punto_recaudo', 'Acceder') ||
          tienePermiso('Cuadre_caja', 'Acceder') ||
          tienePermiso('Recaudo', 'Acceder')
        "
      >
        <span
          style="width: 24px; height: 24px"
          class="material-symbols-outlined"
          >attach_money</span
        >
        <span
          style="margin-right: auto"
          *ngIf="!isCollapsed"
          class="parrafo-regular"
          >Recaudo</span
        >
        <span
          style="
            width: 24px;
            height: 24px;
            color: var(--Foundation-Azul-Principal-500);
          "
          class="material-symbols-outlined"
          >{{ panels[1] ? "keyboard_arrow_up" : "keyboard_arrow_down" }}</span
        >
      </a>
      <!-- item -->
      <a
        class="subItem"
        routerLink="recaudo/consulta-recaudo"
        [ngStyle]="{ display: panels[1] ? 'flex' : 'none' }"
        routerLinkActive="active"
        *ngIf="tienePermiso('Recaudo', 'Acceder')"
      >
        <span
          matTooltip="{{ isCollapsed ? 'Consulta de recaudos' : '' }}"
          matTooltipPosition="after"
          style="width: 24px; height: 24px"
          class="material-symbols-outlined"
          >{{ isCollapsed ? "Apps" : "" }}</span
        >
        <span *ngIf="!isCollapsed" class="parrafo-regular"
          >Consulta de recaudos</span
        >
      </a>
      <a
        class="subItem"
        routerLink="recaudo/punto-recaudo"
        [ngStyle]="{ display: panels[1] ? 'flex' : 'none' }"
        routerLinkActive="active"
        *ngIf="tienePermiso('Punto_recaudo', 'Acceder')"
      >
        <span
          matTooltip="{{ isCollapsed ? 'Punto de recaudo' : '' }}"
          matTooltipPosition="after"
          style="width: 24px; height: 24px"
          class="material-symbols-outlined"
          >{{ isCollapsed ? "Apps" : "" }}</span
        >
        <span *ngIf="!isCollapsed" class="parrafo-regular"
          >Punto de recaudo</span
        >
      </a>
      <a
        class="subItem"
        routerLink="recaudo/cuadre-caja"
        [ngStyle]="{ display: panels[1] ? 'flex' : 'none' }"
        routerLinkActive="active"
        *ngIf="tienePermiso('Cuadre_caja', 'Acceder')"
      >
        <span
          matTooltip="{{ isCollapsed ? 'Cuadre de caja' : '' }}"
          matTooltipPosition="after"
          style="width: 24px; height: 24px"
          class="material-symbols-outlined"
          >{{ isCollapsed ? "Apps" : "" }}</span
        >
        <span *ngIf="!isCollapsed" class="parrafo-regular">Cuadre de caja</span>
      </a>
      <!-- Seguridad -->
      <!-- Accordion -->
      <a
        class="deployItem"
        [ngClass]="{
          openAccordion: panels[2]
        }"
        (click)="togglePanel(2)"
        *ngIf="
          tienePermiso('Usuarios', 'Acceder') ||
          tienePermiso('Roles', 'Acceder')
        "
      >
        <span
          style="width: 24px; height: 24px"
          class="material-symbols-outlined"
          >encrypted</span
        >
        <span
          style="margin-right: auto"
          *ngIf="!isCollapsed"
          class="parrafo-regular"
          >Seguridad</span
        >
        <span
          style="
            width: 24px;
            height: 24px;
            color: var(--Foundation-Azul-Principal-500);
          "
          class="material-symbols-outlined"
          >{{ panels[2] ? "keyboard_arrow_up" : "keyboard_arrow_down" }}</span
        >
      </a>
      <!-- item -->
      <a
        class="subItem"
        routerLink="usuarios"
        [ngStyle]="{ display: panels[2] ? 'flex' : 'none' }"
        routerLinkActive="active"
        *ngIf="tienePermiso('Usuarios', 'Acceder')"
      >
        <span
          matTooltip="{{ isCollapsed ? 'Usuarios' : '' }}"
          matTooltipPosition="after"
          style="width: 24px; height: 24px"
          class="material-symbols-outlined"
          >{{ isCollapsed ? "Apps" : "" }}</span
        >
        <span *ngIf="!isCollapsed" class="parrafo-regular">Usuarios</span>
      </a>
      <a
        class="subItem"
        routerLink="roles"
        [ngStyle]="{ display: panels[2] ? 'flex' : 'none' }"
        routerLinkActive="active"
        *ngIf="tienePermiso('Roles', 'Acceder')"
      >
        <span
          matTooltip="{{ isCollapsed ? 'Roles' : '' }}"
          matTooltipPosition="after"
          style="width: 24px; height: 24px"
          class="material-symbols-outlined"
          >{{ isCollapsed ? "Apps" : "" }}</span
        >
        <span *ngIf="!isCollapsed" class="parrafo-regular">Roles</span>
      </a>
      <!-- Configuración -->
      <!-- Accordion -->
      <a
        class="deployItem"
        [ngClass]="{
          openAccordion: panels[3]
        }"
        (click)="togglePanel(3)"
        *ngIf="
          tienePermiso('Lineas_credito', 'Acceder') ||
          tienePermiso('Puntos_recaudo', 'Acceder') ||
          tienePermiso('Cajas_recaudo', 'Acceder') ||
          tienePermiso('Tipos_documento_recaudo', 'Acceder') ||
          tienePermiso('Tipos_medios_pago', 'Acceder') ||
          tienePermiso('Motivos_anulacion_linea_credito', 'Acceder') ||
          tienePermiso('Tasa_interes_mora', 'Acceder')
        "
      >
        <span
          style="width: 24px; height: 24px"
          class="material-symbols-outlined"
          >settings</span
        >
        <span
          style="margin-right: auto"
          *ngIf="!isCollapsed"
          class="parrafo-regular"
          >Configuración</span
        >
        <span
          style="
            width: 24px;
            height: 24px;
            color: var(--Foundation-Azul-Principal-500);
          "
          class="material-symbols-outlined"
          >{{ panels[3] ? "keyboard_arrow_up" : "keyboard_arrow_down" }}</span
        >
      </a>
      <!-- items -->
      <a
        class="subItem"
        routerLink="configuracion/linea-credito"
        [ngStyle]="{ display: panels[3] ? 'flex' : 'none' }"
        routerLinkActive="active"
        *ngIf="tienePermiso('Lineas_credito', 'Acceder')"
      >
        <span
          matTooltip="{{ isCollapsed ? 'Líneas de crédito' : '' }}"
          matTooltipPosition="after"
          style="width: 24px; height: 24px"
          class="material-symbols-outlined"
          >{{ isCollapsed ? "Apps" : "" }}</span
        >
        <span *ngIf="!isCollapsed" class="parrafo-regular"
          >Líneas de crédito</span
        >
      </a>
      <a
        class="subItem"
        [ngStyle]="{ display: panels[3] ? 'flex' : 'none' }"
        routerLinkActive="active"
        routerLink="companias/puntos-recaudo"
        *ngIf="tienePermiso('Puntos_recaudo', 'Acceder')"
      >
        <span
          matTooltip="{{ isCollapsed ? 'Puntos de recaudo' : '' }}"
          matTooltipPosition="after"
          style="width: 24px; height: 24px"
          class="material-symbols-outlined"
          >{{ isCollapsed ? "Apps" : "" }}</span
        >
        <span *ngIf="!isCollapsed" class="parrafo-regular"
          >Puntos de recaudo</span
        >
      </a>
      <a
        class="subItem"
        [ngStyle]="{ display: panels[3] ? 'flex' : 'none' }"
        routerLinkActive="active"
        routerLink="companias/cajas-recaudo"
        *ngIf="tienePermiso('Cajas_recaudo', 'Acceder')"
      >
        <span
          matTooltip="{{ isCollapsed ? 'Cajas de recaudo' : '' }}"
          matTooltipPosition="after"
          style="width: 24px; height: 24px"
          class="material-symbols-outlined"
          >{{ isCollapsed ? "Apps" : "" }}</span
        >
        <span *ngIf="!isCollapsed" class="parrafo-regular"
          >Cajas de recaudo</span
        >
      </a>
      <a
        class="subItem"
        routerLink="configuracion/tipos-documento"
        [ngStyle]="{ display: panels[3] ? 'flex' : 'none' }"
        routerLinkActive="active"
        *ngIf="tienePermiso('Tipos_documento_recaudo', 'Acceder')"
      >
        <span
          matTooltip="{{ isCollapsed ? 'Tipos de documento' : '' }}"
          matTooltipPosition="after"
          style="width: 24px; height: 24px"
          class="material-symbols-outlined"
          >{{ isCollapsed ? "Apps" : "" }}</span
        >
        <span *ngIf="!isCollapsed" class="parrafo-regular"
          >Tipos de documento</span
        >
      </a>
      <a
        class="subItem"
        routerLink="configuracion/tipos-medio-pago"
        [ngStyle]="{ display: panels[3] ? 'flex' : 'none' }"
        routerLinkActive="active"
        *ngIf="tienePermiso('Tipos_medios_pago', 'Acceder')"
      >
        <span
          matTooltip="{{ isCollapsed ? 'Tipos de medios de pago' : '' }}"
          matTooltipPosition="after"
          style="width: 24px; height: 24px"
          class="material-symbols-outlined"
          >{{ isCollapsed ? "Apps" : "" }}</span
        >
        <span *ngIf="!isCollapsed" class="parrafo-regular"
          >Tipos de medios de pago</span
        >
      </a>
      <a
        class="subItem"
        routerLink="configuracion/medios-pago"
        [ngStyle]="{ display: panels[3] ? 'flex' : 'none' }"
        routerLinkActive="active"
        *ngIf="tienePermiso('Medios_pago_recaudo', 'Acceder')"
      >
        <span
          matTooltip="{{ isCollapsed ? 'Medios de pago' : '' }}"
          matTooltipPosition="after"
          style="width: 24px; height: 24px"
          class="material-symbols-outlined"
          >{{ isCollapsed ? "Apps" : "" }}</span
        >
        <span *ngIf="!isCollapsed" class="parrafo-regular">Medios de pago</span>
      </a>
      <a
        class="subItem"
        routerLink="configuracion/motivos-anulacion-credito"
        [ngStyle]="{ display: panels[3] ? 'flex' : 'none' }"
        routerLinkActive="active"
        *ngIf="tienePermiso('Motivos_anulacion_linea_credito', 'Acceder')"
      >
        <span
          matTooltip="{{ isCollapsed ? 'Motivos de anulación' : '' }}"
          matTooltipPosition="after"
          style="width: 24px; height: 24px"
          class="material-symbols-outlined"
          >{{ isCollapsed ? "Apps" : "" }}</span
        >
        <span *ngIf="!isCollapsed" class="parrafo-regular"
          >Motivos de anulación</span
        >
      </a>
      <a
        class="subItem"
        routerLink="configuracion/tasas-interes-mora"
        [ngStyle]="{ display: panels[3] ? 'flex' : 'none' }"
        routerLinkActive="active"
        *ngIf="tienePermiso('Tasas_interes_mora', 'Acceder')"
      >
        <span
          matTooltip="{{ isCollapsed ? 'Tasas de interés SIC' : '' }}"
          matTooltipPosition="after"
          style="width: 24px; height: 24px"
          class="material-symbols-outlined"
          >{{ isCollapsed ? "Apps" : "" }}</span
        >
        <span *ngIf="!isCollapsed" class="parrafo-regular"
          >Tasas de interés SIC</span
        >
      </a>
      <a
        class="subItem"
        routerLink="configuracion/detalle-usuario-tiendas"
        [ngStyle]="{ display: panels[3] ? 'flex' : 'none' }"
        routerLinkActive="active"
        *ngIf="tienePermiso('Detalle_usuario_tiendas', 'Acceder')"
      >
        <span
          matTooltip="{{ isCollapsed ? 'Puntos de recaudo usuarios' : '' }}"
          matTooltipPosition="after"
          style="width: 24px; height: 24px"
          class="material-symbols-outlined"
          >{{ isCollapsed ? "Apps" : "" }}</span
        >
        <span *ngIf="!isCollapsed" class="parrafo-regular"
          >Puntos de recaudo usuarios</span
        >
      </a>

      <!-- Organizaciones -->
      <!-- Accordion -->
      <a
        class="deployItem"
        [ngClass]="{
          openAccordion: panels[4]
        }"
        (click)="togglePanel(4)"
        *ngIf="tienePermiso('Companias', 'Acceder')"
      >
        <span
          style="width: 24px; height: 24px"
          class="material-symbols-outlined"
          >account_balance</span
        >
        <span
          style="margin-right: auto"
          *ngIf="!isCollapsed"
          class="parrafo-regular"
          >Compañías</span
        >
        <span
          style="
            width: 24px;
            height: 24px;
            color: var(--Foundation-Azul-Principal-500);
          "
          class="material-symbols-outlined"
          >{{ panels[4] ? "keyboard_arrow_up" : "keyboard_arrow_down" }}</span
        >
      </a>
      <!-- items -->
      <a
        class="subItem"
        [ngStyle]="{ display: panels[4] ? 'flex' : 'none' }"
        routerLinkActive="active"
        routerLink="companias/lista"
        *ngIf="tienePermiso('Companias', 'Acceder')"
      >
        <span
          matTooltip="{{ isCollapsed ? 'Compañías' : '' }}"
          matTooltipPosition="after"
          style="width: 24px; height: 24px"
          class="material-symbols-outlined"
          >{{ isCollapsed ? "Apps" : "" }}</span
        >
        <span *ngIf="!isCollapsed" class="parrafo-regular">Compañías</span>
      </a>

      <!-- Reportes -->
      <!-- Accordion -->
      <a
        class="deployItem"
        [ngClass]="{
          openAccordion: panels[6]
        }"
        (click)="togglePanel(6)"
        *ngIf="tienePermiso('Reportes', 'Acceder')"
      >
        <span
          style="width: 24px; height: 24px"
          class="material-symbols-outlined"
          >summarize</span
        >
        <span
          style="margin-right: auto"
          *ngIf="!isCollapsed"
          class="parrafo-regular"
          >Reportes</span
        >
        <span
          style="
            width: 24px;
            height: 24px;
            color: var(--Foundation-Azul-Principal-500);
          "
          class="material-symbols-outlined"
          >{{ panels[6] ? "keyboard_arrow_up" : "keyboard_arrow_down" }}</span
        >
      </a>
      <!-- Items -->
      <a
        class="subItem"
        [ngStyle]="{ display: panels[6] ? 'flex' : 'none' }"
        routerLinkActive="active"
        *ngIf="tienePermiso('Reportes', 'Acceder')"
      >
        <span
          matTooltip="{{ isCollapsed ? 'Reporte 1' : '' }}"
          matTooltipPosition="after"
          style="width: 24px; height: 24px"
          class="material-symbols-outlined"
          >{{ isCollapsed ? "Apps" : "" }}</span
        >
        <span *ngIf="!isCollapsed" class="parrafo-regular">Reporte 1</span>
      </a>
    </div>
    <!-- footer -->
    <div class="sidebar-footer">
      <button mat-icon-button (click)="isCollapsed = !isCollapsed">
        <span class="material-symbols-outlined">{{
          isCollapsed ? "arrow_forward" : "arrow_back"
        }}</span>
      </button>
    </div>
  </mat-sidenav>

  <div style="margin: 0 var(--XL) 0 var(--XL); padding-left: var(--XL)">
    <div
      style="
        height: 40px;
        display: flex;
        flex-direction: row;
        align-items: center;
        position: sticky;
        top: 0;
        padding-top: var(--XL);
        background-color: white;
        z-index: 3;
        justify-content: space-between;
      "
    >
      <div style="gap: 8px" class="flex flex-row">
        <div
          *ngIf="showOrganizacion"
          class="select-container container-select-nav"
        >
          <label>Organización</label>
          <select
            [(ngModel)]="selectedCompanie"
            (change)="onCompanieChange()"
            class="input-select"
            [ngClass]="{ 'input-valid': selectedCompanie }"
          >
            <option value="">Organización</option>
            <option
              [value]="companie.id_compania"
              *ngFor="let companie of companies"
            >
              {{ companie.nombre_compania }}
            </option>
          </select>
        </div>
        <div *ngIf="showSucursal" class="select-container container-select-nav">
          <label for="sucursal">Sucursal</label>
          <select
            [(ngModel)]="selectedSucursal"
            (change)="onSucursalChange(selectedSucursal)"
            name="sucursal"
            class="input-select"
            [ngClass]="{ 'input-valid': selectedSucursal }"
          >
            <option value="">Sucursal</option>
            <option
              [value]="sucursal.id_punto"
              *ngFor="let sucursal of sucursales"
            >
              {{ sucursal.nombre_punto }}
            </option>
          </select>
        </div>
        <div *ngIf="showCaja" class="select-container container-select-nav">
          <label for="caja">Caja</label>
          <select
            [(ngModel)]="selectedCaja"
            [disabled]="!selectedSucursal"
            name="caja"
            class="input-select"
            [ngClass]="{ 'input-valid': selectedCaja }"
          >
            <option value="">Caja</option>
            <option [value]="caja.id_caja" *ngFor="let caja of cajas">
              {{ caja.nombre_caja }}
            </option>
          </select>
        </div>
        <div
          *ngIf="showTipoRecibo"
          class="select-container container-select-nav"
        >
          <label for="tipoRecibo">Tipo de recibo</label>
          <select
            [(ngModel)]="selectedTipoRecibo"
            name="tipoRecibo"
            class="input-select"
            [ngClass]="{ 'input-valid': selectedTipoRecibo }"
          >
            <option value="">Tipo de recibo</option>
            <option
              [value]="tipoRecibo.id_tipo_documento"
              *ngFor="let tipoRecibo of tiposDeRecibos"
            >
              {{ tipoRecibo.nombre_tipo_documento }}
            </option>
          </select>
        </div>
      </div>
      <div
        style="
          padding: var(--L) var(--XL);
          gap: var(--XXL);
          border-radius: var(--Full);
          background: #efeef5;
          width: 300px;
          height: 40px;
          box-sizing: border-box;
        "
        class="flex flex row items-center justify-between"
      >
        <div class="flex flex-row items-center" style="gap: var(--L)">
          <span style="width: 40px" class="material-symbols-outlined"
            >account_circle</span
          >

          <span
            class="parrafo-bold"
            style="
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              max-width: 100px;
            "
            >{{ user.usuario }}</span
          >
        </div>

        <!-- <mat-icon
          matTooltip="Cerrar sesión"
          class="hyperlink-icon"
          (click)="cerrarSesion()"
          >exit_to_app</mat-icon
        > -->
        <div
          style="white-space: nowrap; overflow: hidden; max-width: 100px"
          (click)="abrirDialogoConfirmacion()"
        >
          <span class="hyperlink">Cerrar sesión</span>
        </div>
      </div>
    </div>
    <div>
      <router-outlet></router-outlet>
    </div>
  </div>
</mat-sidenav-container>
